import React from "react";
import Hero from "../containers/Hero";

const Home = () => (
  <>
    <Hero />
  </>
);

export default Home;
